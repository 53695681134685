// Global
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import LinkWrapper from 'src/helpers/LinkWrapper/LinkWrapper';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
export type RaqMobileBannerProps =
  Feature.EnterpriseWeb.AndersenWindows.Components.Navigation.RaqMobileBanner.RaqMobileBanner;
const RaqMobileBanner = (props: RaqMobileBannerProps) => {
  return (
    <div className="bg-primary py-1 text-white">
      <div className="flex  flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-center">
          <div className="text-center">
            {props?.props?.fields?.textBeforeCta?.value && (
              <Text
                tag={'h3'}
                field={{ value: props?.props?.fields?.textBeforeCta.value }}
                className="font-sans text-sm"
              />
            )}
          </div>

          <div className="flex flex-row">
            {props?.props.fields?.cta1Link && (
              <>
                <LinkWrapper
                  field={{ value: props.props?.fields?.cta1Link.value }}
                  className="font-sans text-sm font-bold underline"
                />
                <Text
                  tag={'h3'}
                  field={{ value: props?.props?.fields?.textAfterCta.value }}
                  className="m-auto pl-1 font-sans text-sm"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaqMobileBanner;
