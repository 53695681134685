import { useTheme } from 'lib/context/ThemeContext';
import LinkWrapper from 'src/helpers/LinkWrapper/LinkWrapper';
import { IconTypes } from 'src/helpers/SvgIcon';
import SvgIcon from 'src/helpers/SvgIcon/SvgIcon';
import { getEnum } from 'lib/utils';
import classNames from 'classnames';
import { ButtonProps } from 'src/helpers/Button/Button';

type HelperButtonProps = Omit<ButtonProps, 'variant'>;

export const HelperButtonClasses = (themeName: string) => {
  if (themeName === 'aw') {
    return {
      btnClass:
        'flex w-fit h-[40px] sm:h-[45px] lg:h-[66px] md:h-[50px]  md:w-[280px] whitespace-nowrap sm:w-[190px] items-center justify-center whitespace-normal rounded-lg border-4 border-theme-btn-border bg-theme-btn-bg py-[9px] font-sans text-button font-heavy sm:font-xs text-theme-btn-text md:text-base sm:text-[12.5px]',
      iconClass: 'ml-xxs',
    };
  } else {
    return {
      btnClass:
        'group relative flex w-fit items-center bg-theme-btn-bg p-s font-serif text-button font-bold text-theme-btn-text hover:bg-theme-btn-bg-hover hover:text-theme-btn-text-hover disabled:bg-gray disabled:text-dark-gray disabled:cursor-not-allowed',
      iconClass:
        'ml-[10px] text-theme-btn-primary-icon group-hover:text-theme-btn-primary-icon-hover group-disabled:text-dark-gray',
    };
  }
};

const HelperButton = (props: HelperButtonProps): JSX.Element => {
  const { themeName } = useTheme();
  const { field, icon, classes, ariaLabel } = props;
  const _icon = getEnum<IconTypes>(icon);

  if (field) {
    return (
      <LinkWrapper
        field={field}
        ariaLabel={ariaLabel}
        className={classNames(HelperButtonClasses(themeName).btnClass, classes)}
      >
        {icon && <SvgIcon icon={_icon} className={HelperButtonClasses(themeName).iconClass} />}
      </LinkWrapper>
    );
  }

  return <></>;
};

export default HelperButton;
