// Lib
import { ThemeFile } from 'lib/context/ThemeContext';

export const ToggleBannerTheme: ThemeFile = {
  aw: {
    classes: {
      /** Insert Theme classes here **/
      headlineWrapper:
        'col-span-8 flex-1 p-4 lg:p-10 md:p-[5px] sm:p-[5px] text-center sm:pt-[24px] md:pt-[24px]',
      mainWrapper: 'bg-[#f26924] col-span-12 flex flex-col md:flex-col lg:flex-row',
      buttonWrappers:
        ' col-span-2 flex flex-1 justify-center p-4 lg:p-10 md:p-[5px] sm:p-[5px] pr-0 sm:pr-[39px] pb-0 md:pb-[10px] md:pb-[24px] sm:pb-[24px] md:self-center',
    },
  },
  rba: {
    classes: {
      /** Insert Theme classes here **/
    },
  },
};
