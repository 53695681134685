import { ThemeFile } from 'lib/context/ThemeContext';
import { ContentBlockWithMediaAndProductProps } from './ContentBlockWithMediaAndProduct';
import classNames from 'classnames';
import { ButtonVariants } from 'src/helpers/Button';

const getImageOuterContainerClasses = (props: ContentBlockWithMediaAndProductProps): string => {
  return props.fields?.primaryImage?.value?.src && props.fields?.secondaryImage?.value?.src
    ? classNames('mb-m')
    : classNames('mb-m col-span-2');
};
const getSecondCTAPadding = (ctaStyle: ButtonVariants) => {
  return (ctaStyle === 'link' || ctaStyle === 'link-right-icon') && 'ml-xs md:ml-0 px-s';
};

export const ContentBlockWithMediaAndProductTheme = (
  props: ContentBlockWithMediaAndProductProps,
  cta2Style: ButtonVariants
): ThemeFile => {
  return {
    aw: {
      classes: {
        headingContainer: 'col-span-12',
        headlineContainer: 'text-s md:text-m font-heavy pb-xxs md:pb-s mb-m',
        headlineContainerMobile: 'text-lg pb-xxs pb-s font-bold ',
        headlineContainerProductDescription:
          'text-s md:text-s font-heavy pb-xxs md:pb-s text-center',
        headlineContainerProductUsed: 'text-s md:text-s font-sans text-left font-bold pb-2.5 ',
        topCopyContainer: 'pb-s font-regular font-serif text-theme-body',
        productDescription: 'pb-s font-regular font-serif text-base font-heavy',
        productDescriptionMobile: 'pb-s font-regular font-serif text-base font-heavy text-center',
        topCopySubtitle: 'font-regular text-theme-body font-extrabold text-black',
        bodyContainer: 'col-span-12 font-regular font-serif mb-s',
        imageOuterContainer: classNames(getImageOuterContainerClasses(props)),
        imageContainer: classNames('img-container grid grid-cols-1 md:grid-cols-3 md:gap-s'),
        captionContainer: 'mt-xxs italic text-left mb-s font-sans text-sm-xxs md:text-caption',
        contentWrapper: classNames('col-span-12', 'md:w-full', 'md:max-w-screen-lg', 'md:mx-auto'),
        buttonGroupClass: {
          wrapper: 'flex-col',
          cta1Classes: classNames('mb-6 md:mb-0'),
          cta2Classes: classNames(getSecondCTAPadding(cta2Style)),
          cta3Classes: 'mb-m md:mb-0',
        },
      },
    },
    rba: {
      classes: {
        headingContainer: 'col-span-12',
        headlineContainer: 'text-theme-text text-sm-m md:text-m font-medium mb-s',
        topCopyContainer: 'mb-s font-regular font-serif text-theme-body',
        bodyContainer: 'col-span-12 font-regular font-serif mb-s text-theme-body',
        imageOuterContainer: classNames('mb-s'),
        imageContainer: classNames('img-container grid grid-cols-1 md:grid-cols-3 md:gap-s'),
        captionContainer:
          'mt-xxs md:mt-xxxs border-primary border-l-2 pl-xxs text-left mb-s text-sm-xxs md:text-body',
      },
    },
  };
};
